.subjectContent[data-v-9242bd25] {
  display: flex;
  overflow: hidden;
  color: #1890ff;
  cursor: pointer;
}
.subjectContent .label[data-v-9242bd25] {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  height: auto;
}
.editBtn[data-v-9242bd25]:hover {
  text-decoration: underline;
}
